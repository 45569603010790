import {
    durationBetween,
    unitsBetween,
    durationToUnit,
    addUnitToDate,
    divideDurations,
    addDurationToDate,
    multiplyDurationBy,
    divideDurationBy,
    subDurations,
} from '@ryki/datemath'
import _ from 'lodash'
import {
    observable,
    computed,
    action,
    makeObservable,
    get,
    entries,
    keys,
} from 'mobx'
import bind from 'bind-decorator'
import ContactCollection from '../Collections/ContactCollection'
import CostCentreCollection from '../Collections/CostCentreCollection'
import DailyAllocationCollection from '../Collections/DailyAllocationCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import ProjectRateCollection from '../Collections/ProjectRateCollection'
import StaffCollection from '../Collections/StaffCollection'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import Model from './Model'
import pluralize from 'pluralize'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectNoteCollection from '../Collections/ProjectNoteCollection'
import ChangeLogCollection from '../Collections/ChangeLogCollection'
import { statusByPriority, statusPriorities } from '../../Utils/statusPriority'
import TimeEntryAggregateCollection from '../Aggregates/TimeEntryAggregateCollection'
import InvoiceLineItemAggregateCollection from '../Aggregates/InvoiceLineItemAggregateCollection'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import RevenueTargetCollection from '../Collections/RevenueTargetCollection'
import BudgetCollection from '../Collections/BudgetCollection'
import TaskCollection from '../Collections/TaskCollection'
import ProjectExpenseAllocationCollection from '../Collections/ProjectExpenseAllocationCollection'
import AllocationCollection from '../Collections/AllocationCollection'
import {
    addDays,
    addMilliseconds,
    differenceInDays,
    endOfDay,
    startOfDay,
} from 'date-fns'
import { ca } from 'date-fns/locale'
import sortPhases from '../../Utils/sortPhases'

class ProjectModel extends Model {
    @observable jobNumber = null
    @observable name = null

    @observable ownerId = null
    @observable contactId = null
    @observable primaryContactId = null
    @observable costCentreId = null
    @observable rootPhaseId = null

    @observable milestoneType = null
    @observable address = null
    @observable invoiceRef = null
    @observable invoiceOpen = null
    @observable invoiceClose = null
    @observable durationUnit = 'weeks'
    @observable feeData = null

    @observable _startDate = null
    @observable _endDate = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = ProjectCollection
        this.init(data, options)
    }
    @computed
    get title() {
        return this.jobNumber && this.name
            ? `${this.jobNumber}: ${this.name}`
            : this.name || this.jobNumber
    }
    @computed
    get owner() {
        return StaffCollection.modelsById[this.ownerId]
    }
    @computed
    get contact() {
        return ContactCollection.modelsById[this.contactId]
    }
    @computed
    get primaryContact() {
        return ContactCollection.modelsById[this.primaryContactId]
    }
    @computed
    get costCentre() {
        return CostCentreCollection.modelsById[this.costCentreId]
    }

    @computed
    get allocations() {
        return AllocationCollection.allocationsByProjectId[this.id] || []
    }

    @computed
    get dailyAllocations() {
        return (
            DailyAllocationCollection.dailyAllocationsByProjectId[this.id] || []
        )
    }

    @computed
    get rootPhase() {
        return (
            PhaseCollection.modelsById[this.rootPhaseId] ||
            this.phases.find((ph) => ph?.isRootPhase) || {
                id: this.rootPhaseId,
            }
        )
    }

    @computed
    get phases() {
        return (PhaseCollection.phasesByProjectId[this.id] || []).sort(
            sortPhases
        )
    }

    @computed
    get expenses() {
        return ProjectExpenseCollection.expensesByProjectId[this.id] || []
    }

    @computed
    get expenseAllocations() {
        return (
            ProjectExpenseAllocationCollection.expenseAllocationsByProjectId[
                this.id
            ] || []
        )
    }

    @computed
    get rates() {
        return ProjectRateCollection.ratesByProjectId[this.id] || []
    }
    @computed
    get ratesWithOutPhases() {
        return this.rates.filter(
            (r) => !r.phaseId || r.phase === this.rootPhase
        )
    }

    @computed
    get timeEntries() {
        return TimeEntryCollection.timeEntriesByProjectId[this.id] || []
    }

    @computed
    get invoiceLineItems() {
        return InvoiceLineItemCollection.lineItemsByProjectId[this.id] || []
    }

    @computed
    get revenueTargets() {
        return RevenueTargetCollection.revenueTargetsByProjectId[this.id] || []
    }

    @computed
    get status() {
        return (
            this._status ||
            statusByPriority[
                _.min(this.phases.map((ph) => statusPriorities[ph.status]))
            ]
        )
    }

    @computed
    get phaseStatuses() {
        return this.phases.map((ph) => ph.status)
    }

    @computed
    get startDate() {
        return (
            this._startDate ||
            _.min(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.startDate)
            )
        )
    }

    @computed
    get endDate() {
        return (
            this._endDate ||
            _.max(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.endDate)
            )
        )
    }

    @computed
    get fee() {
        return (
            this._fee ||
            _.sum(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.fee)
            )
        )
    }

    @computed
    get expenseBudget() {
        return (
            this._expenseBudget ||
            _.sum(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.expenseBudget)
            )
        )
    }

    @computed
    get hoursBudget() {
        return (
            this._hoursBudget ||
            _.sum(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.hoursBudget)
            ) ||
            0
        )
    }

    @computed
    get percentLikelihood() {
        return (
            this._percentLikelihood ||
            _.sum(
                this.phases
                    .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                    .map((ph) => ph.feeAdjustedByLikelihood)
            ) / this.fee
        )
    }

    @bind
    getAggregateInvoiceLineItems(group) {
        return (
            InvoiceLineItemAggregateCollection.getGroupCollection(group)
                .lineItemsByProjectId[this.id] || []
        )
    }
    @bind
    getAggregateTimeEntries(group) {
        return (
            TimeEntryAggregateCollection.getGroupCollection(group)
                .timeEntriesByProjectId[this.id] || []
        )
    }

    @computed
    get invoices() {
        return InvoiceCollection.invoicesByProjectId[this.id] || []
    }

    @computed
    get notes() {
        return ProjectNoteCollection.notesByProjectId[this.id] || []
    }

    @computed
    get changeLog() {
        return ChangeLogCollection.changeLogItemsByProjectId[this.id] || []
    }

    @computed
    get duration() {
        return unitsBetween(this.startDate, this.endDate, this.durationUnit)
    }

    @computed
    get feeAdjustedByLikelihood() {
        return _.sum(this.phases.map((ph) => ph.feeAdjustedByLikelihood))
    }
    @computed
    get budgets() {
        return BudgetCollection.budgetsByProjectId[this.id] || []
    }
    @computed
    get hoursFromBudgets() {
        return _.sum(this.budgets.map((b) => b.hours))
    }
    @computed
    get costFromBudgets() {
        return _.sum(this.budgets.map((b) => b.cost))
    }

    @computed
    get costRate() {
        return _.mean(this.budgets.map((b) => b.costRate))
    }

    @computed
    get chargeOutRate() {
        return _.mean(this.budgets.map((b) => b.chargeOutRate))
    }

    @computed
    get tasks() {
        return TaskCollection.tasksByProjectId[this.id] || []
    }

    @action.bound
    updatePhaseValues(data) {
        this.phases.forEach((ph) => ph.update(data))
    }

    @action.bound
    scaleValueAcrossPhases(prop, value) {
        const total = this[prop]
        const multiplier = total ? value / total : 0
        const numPhases = this.phases.length
        this.phases.forEach((ph) => {
            const data = {}
            data[prop] = multiplier ? ph[prop] * multiplier : value / numPhases
            ph.update(data)
        })
        // this.update({ [prop]: value })
    }

    @action.bound
    scaleDatesAcrossPhases({ startDate, endDate, duration }) {
        const newStartDate = startDate ?? this.startDate
        const newEndDate =
            endDate ||
            (duration
                ? addUnitToDate(newStartDate, duration.unit, duration.value)
                : this.endDate)
        if (
            newEndDate &&
            newStartDate &&
            this.startDate &&
            this.endDate &&
            this.endDate - this.startDate
        ) {
            const newDuration =
                startOfDay(newEndDate) - startOfDay(newStartDate)
            const oldDuration =
                startOfDay(this.endDate) - startOfDay(this.startDate)
            const multiplier =
                this.startDate && this.endDate ? newDuration / oldDuration : 0
            const cachedStartDate = startOfDay(this.startDate)
            this.phases
                .filter((ph) => !ph?.deletedAt && !ph?.isRootPhase)
                .forEach((ph) => {
                    const data = {}
                    if (ph.startDate && ph.endDate) {
                        const distanceToStart =
                            startOfDay(ph.startDate) - cachedStartDate
                        const newDistanceToStart = distanceToStart * multiplier
                        const distanceToEnd =
                            startOfDay(ph.endDate) - cachedStartDate
                        const newDistanceToEnd = distanceToEnd * multiplier
                        data.startDate = addMilliseconds(
                            startOfDay(newStartDate),
                            newDistanceToStart
                        )
                        data.endDate = addMilliseconds(
                            startOfDay(newStartDate),
                            newDistanceToEnd
                        )
                        ph.update(data)
                    }
                })
        } else {
            this.phases.forEach((ph) => {
                const data = {}
                if (newStartDate) data.startDate = newStartDate
                if (newEndDate) data.endDate = newEndDate
                ph.update(data)
            })
        }
        this.update({ _startDate: newStartDate, _endDate: newEndDate })
    }
}

export default ProjectModel
